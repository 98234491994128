@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

:root {
  --color-background: #0F172A;
  --color-text: #fff;
  --color-primary: #9198e5;
  --color-secondary: #e66465;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.errmsg {
  display: inline-block;
  background-color: #0F172A;
  color:red;
  padding: 0.25em;
  margin-bottom: 0.5em;
}

/* || GENERAL STYLES */
body  {
  font: 1.5rem 'Nunito', sans-serif;
  font-display: fallback;
  min-height: 100vh;
  background-color: var(--color-background);
  color: var(--color-text);
}

a {
  color: whitesmoke;
  text-decoration: none;
}

table a:visited {
  color: teal;
}

a:hover, a:focus {
  color: rgba(245, 245, 245, 0.9);
}

input, button {
  font: inherit;
  margin: 0 0.3em 0 0;
}

/* || LOGIN */
.login {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.login form {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  margin-top: 1rem;
  max-width: 20em;
}

.login input, .login button {
  padding: 0.5em;
  border-radius: 15px;
}

/* || PAGES */
.public, .welcome, .users {
  padding-top: 1em;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
}

.public > main :first-child::first-letter {
  font-size: 1.5em;
  color: var(--color-primary);
}

.public main {
  flex-grow: 1;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  padding: 1rem;
}

.users ul {
  list-style-type: none;
}

.color-nav {
  background-color: yellow;
}

/* override bootstrap navbar colors */
.navbar,
.navbar-default {
    background-image: linear-gradient(to right, var(--color-secondary), var(--color-primary) 100%) !important; /* override background image gradient w/ flat color */
}

body.active-modal {
  overflow-y: hidden;
}

.btn-modal {
  padding: 10px 20px;
  display: block;
  margin: 100px auto 0;
  font-size: 18px;
}

.modal, .overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: 'red' !important;
  color: #f1f1f1
}

.overlay {
  background: rgba(49,49,49,0.8);
}

.modal-content {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
  background-color: var(--color-secondary);
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}